<template>
  <div class="ContactPerson">
    <div class="searchNav">      
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.CustomerName"
          placeholder="请输入客户名称"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <el-table-column prop="cCusName" label="客户"> </el-table-column>
        <el-table-column prop="DelayDays" label="账期"> </el-table-column>
        <el-table-column prop="OverdueDay" label="逾期天数"> </el-table-column>
        <el-table-column prop="TotalSum" label="逾期金额"> 
          <template slot-scope="scope">
            {{ toThousands(scope.row.TotalSum-scope.row.CollectionSum) }}
          </template>
        </el-table-column>
        <el-table-column prop="CreateTime" label="签收日期">
          <template slot-scope="scope">
            {{ scope.row.CreateTime?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              >催款</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" /> 
    <CallForPay ref="CallForPay" :parentObj="{ ...this }" /> 
    <Form ref="Form" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup.vue";
import { getMonthRange, toThousands} from '../../utils/Tools';
import ReceiptPage from "./Receipt.vue"
import CallForPay from "./CallForPay.vue"
import Form from "./Form.vue"
export default {
  props: {},
  components: {
    Form,
    radioGroup,
    ReceiptPage,
    CallForPay
  },
  mounted() {},
  created() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {      
      getMonthRange,
      toThousands,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      fileList: [],
      queryParam: { CompanyId: "全部" },
      loading: false,
      Email: "",
      row: {},
    };
  },
  methods: {
    radioComplete(e) {
      if (e) {
        this.queryParam.CompanyId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.CompanyId = e;
      this.getDataList();
    },
    handleView(row) {
      // let url = `${location.href.split("#")[0]}#/App/Receipt?Id=${row.Id}`;
      // window.open(url, "_blank");
      console.log('点击查看',row);

      const loading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.$http
        .post("/BO/BIlling/GetOrderReturnId?Id=" + row.Id)
        .then((res) => {
          if (res.Success) {
           if(res.Data.Type ==0 ){
              console.log('打开小票页面')
              // 打开小票页面
              this.$refs.receiptPage.openForm(res.Data.Id);
              setTimeout(() => {
                loading.close();
              }, 400);
            }else{
               // 打开回执单页面
               this.$refs.Form.openForm(res.Data.Id);
               setTimeout(() => {
                loading.close();
              }, 2500);
            }
          } else {
            setTimeout(() => {
                loading.close();
            }, 2500);
          }
        });
    },
    handleEdit(row){
      this.$refs.CallForPay.openForm(row);
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/BO/BIlling/GetCompanyBIllingOverdueList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: "CreateTime",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.del {
  color: red;
}
</style>
